<template>
  <el-select v-model="value">
    <el-option :value="0" :label="zeroTips"></el-option>
    <el-option :value="item.id" :label="item.title" v-for="(item,key) in list" :key="key"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "YChoosePosition",
  model:{
    prop:"modelVal",
    event:'valChange'
  },
  props:{
    modelVal: {
      type:Number,
      default:0,
    },
    department_id:{
      type:Number,
      default: 0,
    }
  },
  data(){
    return{
      list:[],
      value:0,
    }
  },
  watch:{
    department_id:{
      handler:"load",
      immediate:true
    },
    modelVal(){
      this.value = this.modelVal;
    },
    value(e){
      this.$emit('valChange',e);
    },
  },
  methods:{
    load(){
      if (this.department_id <= 0)return;
      this.value = 0;
      this.$u.api.card.positionAll({
        department_id:this.department_id
      }).then(res=>{
        this.list = res;
      })
    }
  },
  computed:{
    zeroTips(){
      return this.department_id > 0 ? "未选择" : "请先选择部门";
    }
  }
}
</script>

<style scoped>

</style>