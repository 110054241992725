<template>
  <div>
    <el-card>
      <el-row :gutter="40">
        <el-col :span="8">
          <el-form >
            <el-form-item label="系统用户" class="flex-def">
              <YChooseMember v-model="form.uid" width-input>
                <el-button>选择用户</el-button>
              </YChooseMember>
            </el-form-item>
            <el-form-item label="名片部门">
              <y-choose-department v-model="form.department_id"></y-choose-department>
            </el-form-item>
            <el-form-item label="名片职位">
              <y-choose-position :department_id="positionUseDepartmentId" v-model="form.position_id"></y-choose-position>
            </el-form-item>
            <el-form-item label="名片姓名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="名片头像">
              <y-upload-img v-model="form.avatar"></y-upload-img>
            </el-form-item>
            <el-form-item label="名片手机">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8">
          <el-form >
            <el-form-item label="微信号">
              <el-input v-model="form.wechat_account"></el-input>
            </el-form-item>
            <el-form-item label="企业微信号">
              <el-input v-model="form.work_wechat_account"></el-input>
            </el-form-item>
            <el-form-item label="座机">
              <el-input v-model="form.landline"></el-input>
            </el-form-item>
            <el-form-item label="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8">
          <el-form >
            <el-form-item label="背景音乐">
              <y-upload-audio-box v-model="form.bg_audio"></y-upload-audio-box>
            </el-form-item>
            <el-form-item label="自我描述">
              <el-input v-model="form.self_desc"></el-input>
            </el-form-item>
            <el-form-item label="名片样式">
              <el-radio-group v-model="form.style_mode">
                <el-radio :label="0">默认</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">提交</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import YChooseMember from "@/components/shop/y_choose_member";
import YUploadAudioBox from "@/components/y-form/y-upload/y_upload_audio";
import YChooseDepartment from "@/components/card/y-choose-department/YChooseDepartment";
import YChoosePosition from "@/components/card/y-choose-position/YChoosePosition";
import YUploadImg from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "edit",
  components: {YUploadImg, YChoosePosition, YChooseDepartment, YUploadAudioBox, YChooseMember},
  data(){
    return{
      form:{
        uid:0,
        name:"",
        avatar:"",
        department_id:0,
        position_id:0,
        phone:"",
        wechat_account:"",
        work_wechat_account:"",
        landline:"",
        email:"",
        bg_audio:"",
        self_desc:"",
        style_mode:0,
      },
      edit_id:0,
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  methods:{
    load(){
      this.$u.api.card.staffOne({id:this.edit_id}).then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.card.staffEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.$router.back();
      })
    }
  },
  computed:{
    positionUseDepartmentId(){
      return this.form.department_id
    }
  }
}
</script>

<style scoped>

</style>